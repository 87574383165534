@import url(https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i);





body {
  font-style: normal;
  line-height: 1.5;
}
.mbr-section-title {
  font-style: normal;
  line-height: 1.2;
}
.mbr-section-subtitle {
  line-height: 1.3;
}
.mbr-text {
  font-style: normal;
  line-height: 1.6;
}
.display-1 {
  font-family: 'Rubik', sans-serif;
  font-size: 4.25rem;
}
.display-1 > .mbr-iconfont {
  font-size: 6.8rem;
}
.display-2 {
  font-family: 'Rubik', sans-serif;
  font-size: 3rem;
}
.display-2 > .mbr-iconfont {
  font-size: 4.8rem;
}
.display-4 {
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
}
.display-4 > .mbr-iconfont {
  font-size: 1.6rem;
}
.display-5 {
  font-family: 'Rubik', sans-serif;
  font-size: 1.5rem;
}
.display-5 > .mbr-iconfont {
  font-size: 2.4rem;
}
.display-7 {
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
}
.display-7 > .mbr-iconfont {
  font-size: 1.6rem;
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 768px) {
  .display-1 {
    font-size: 3.4rem;
    font-size: calc( 2.1374999999999997rem + (4.25 - 2.1374999999999997) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (2.1374999999999997rem + (4.25 - 2.1374999999999997) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-2 {
    font-size: 2.4rem;
    font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-4 {
    font-size: 0.8rem;
    font-size: calc( 1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-5 {
    font-size: 1.2rem;
    font-size: calc( 1.175rem + (1.5 - 1.175) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.175rem + (1.5 - 1.175) * ((100vw - 20rem) / (48 - 20))));
  }
}
/* Buttons */
.btn {
  font-weight: 500;
  border-width: 2px;
  font-style: normal;
  letter-spacing: 1px;
  margin: .4rem .8rem;
  white-space: normal;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  display: -webkit-inline-flex;
  padding: 1rem 3rem;
  border-radius: 3px;
}
.btn-sm {
  font-weight: 500;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0.6rem 1.5rem;
  border-radius: 3px;
}
.btn-md {
  font-weight: 500;
  letter-spacing: 1px;
  margin: .4rem .8rem !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 1rem 3rem;
  border-radius: 3px;
}
.btn-lg {
  font-weight: 500;
  letter-spacing: 1px;
  margin: .4rem .8rem !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 1.2rem 3.2rem;
  border-radius: 3px;
}
.bg-primary {
  background-color: #149dcc !important;
}
.bg-success {
  background-color: #f7ed4a !important;
}
.bg-info {
  background-color: #82786e !important;
}
.bg-warning {
  background-color: #879a9f !important;
}
.bg-danger {
  background-color: #b1a374 !important;
}
.btn-primary,
.btn-primary:active {
  background-color: #149dcc !important;
  border-color: #149dcc !important;
  color: #ffffff !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #ffffff !important;
  background-color: #0d6786 !important;
  border-color: #0d6786 !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #0d6786 !important;
  border-color: #0d6786 !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: #ff3366 !important;
  border-color: #ff3366 !important;
  color: #ffffff !important;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: #ffffff !important;
  background-color: #e50039 !important;
  border-color: #e50039 !important;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #e50039 !important;
  border-color: #e50039 !important;
}
.btn-info,
.btn-info:active {
  background-color: #82786e !important;
  border-color: #82786e !important;
  color: #ffffff !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #ffffff !important;
  background-color: #59524b !important;
  border-color: #59524b !important;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #59524b !important;
  border-color: #59524b !important;
}
.btn-success,
.btn-success:active {
  background-color: #f7ed4a !important;
  border-color: #f7ed4a !important;
  color: #3f3c03 !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #3f3c03 !important;
  background-color: #eadd0a !important;
  border-color: #eadd0a !important;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #3f3c03 !important;
  background-color: #eadd0a !important;
  border-color: #eadd0a !important;
}
.btn-warning,
.btn-warning:active {
  background-color: #879a9f !important;
  border-color: #879a9f !important;
  color: #ffffff !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #ffffff !important;
  background-color: #617479 !important;
  border-color: #617479 !important;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff !important;
  background-color: #617479 !important;
  border-color: #617479 !important;
}
.btn-danger,
.btn-danger:active {
  background-color: #b1a374 !important;
  border-color: #b1a374 !important;
  color: #ffffff !important;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #ffffff !important;
  background-color: #8b7d4e !important;
  border-color: #8b7d4e !important;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #8b7d4e !important;
  border-color: #8b7d4e !important;
}
.btn-white {
  color: #333333 !important;
}
.btn-white,
.btn-white:active {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #808080 !important;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #808080 !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #808080 !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.btn-black,
.btn-black:active {
  background-color: #333333 !important;
  border-color: #333333 !important;
  color: #ffffff !important;
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #0d0d0d !important;
  border-color: #0d0d0d !important;
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #0d0d0d !important;
  border-color: #0d0d0d !important;
}
.btn-primary-outline,
.btn-primary-outline:active {
  background: none;
  border-color: #0b566f;
  color: #0b566f;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: #ffffff;
  background-color: #149dcc;
  border-color: #149dcc;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #ffffff !important;
  background-color: #149dcc !important;
  border-color: #149dcc !important;
}
.btn-secondary-outline,
.btn-secondary-outline:active {
  background: none;
  border-color: #cc0033;
  color: #cc0033;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #ffffff;
  background-color: #ff3366;
  border-color: #ff3366;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #ffffff !important;
  background-color: #ff3366 !important;
  border-color: #ff3366 !important;
}
.btn-info-outline,
.btn-info-outline:active {
  background: none;
  border-color: #4b453f;
  color: #4b453f;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: #ffffff;
  background-color: #82786e;
  border-color: #82786e;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #82786e !important;
  border-color: #82786e !important;
}
.btn-success-outline,
.btn-success-outline:active {
  background: none;
  border-color: #d2c609;
  color: #d2c609;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: #3f3c03;
  background-color: #f7ed4a;
  border-color: #f7ed4a;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #3f3c03 !important;
  background-color: #f7ed4a !important;
  border-color: #f7ed4a !important;
}
.btn-warning-outline,
.btn-warning-outline:active {
  background: none;
  border-color: #55666b;
  color: #55666b;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: #ffffff;
  background-color: #879a9f;
  border-color: #879a9f;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #ffffff !important;
  background-color: #879a9f !important;
  border-color: #879a9f !important;
}
.btn-danger-outline,
.btn-danger-outline:active {
  background: none;
  border-color: #7a6e45;
  color: #7a6e45;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: #ffffff;
  background-color: #b1a374;
  border-color: #b1a374;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #b1a374 !important;
  border-color: #b1a374 !important;
}
.btn-black-outline,
.btn-black-outline:active {
  background: none;
  border-color: #000000;
  color: #000000;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #ffffff;
  background-color: #333333;
  border-color: #333333;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #333333 !important;
  border-color: #333333 !important;
}
.btn-white-outline,
.btn-white-outline:active,
.btn-white-outline.active {
  background: none;
  border-color: #ffffff;
  color: #ffffff;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus {
  color: #333333;
  background-color: #ffffff;
  border-color: #ffffff;
}
.text-primary {
  color: #149dcc !important;
}
.text-secondary {
  color: #ff3366 !important;
}
.text-success {
  color: #f7ed4a !important;
}
.text-info {
  color: #82786e !important;
}
.text-warning {
  color: #879a9f !important;
}
.text-danger {
  color: #b1a374 !important;
}
.text-white {
  color: #ffffff !important;
}
.text-black {
  color: #000000 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #0b566f !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #cc0033 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #d2c609 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #4b453f !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #55666b !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #7a6e45 !important;
}
a.text-white:hover,
a.text-white:focus {
  color: #b3b3b3 !important;
}
a.text-black:hover,
a.text-black:focus {
  color: #4d4d4d !important;
}
.alert-success {
  background-color: #70c770;
}
.alert-info {
  background-color: #82786e;
}
.alert-warning {
  background-color: #879a9f;
}
.alert-danger {
  background-color: #b1a374;
}
.mbr-section-btn a.btn:not(.btn-form) {
  border-radius: 100px;
}
.mbr-section-btn a.btn:not(.btn-form):hover,
.mbr-section-btn a.btn:not(.btn-form):focus {
  box-shadow: none !important;
}
.mbr-section-btn a.btn:not(.btn-form):hover,
.mbr-section-btn a.btn:not(.btn-form):focus {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important;
}
.mbr-gallery-filter li a {
  border-radius: 100px !important;
}
.mbr-gallery-filter li.active .btn {
  background-color: #149dcc;
  border-color: #149dcc;
  color: #ffffff;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
.nav-tabs .nav-link {
  border-radius: 100px !important;
}
.btn-form {
  border-radius: 0;
}
.btn-form:hover {
  cursor: pointer;
}
a,
a:hover {
  color: #149dcc;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #b4e6f8;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #ffffff;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #beb8b2;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ced6d8;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #dfd9c6;
}
/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}
#scrollToTop a i:before {
  content: '';
  position: absolute;
  height: 40%;
  top: 25%;
  background: #fff;
  width: 2px;
  left: calc(50% - 1px);
}
#scrollToTop a i:after {
  content: '';
  position: absolute;
  display: block;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 40%;
  height: 40%;
  left: 30%;
  bottom: 30%;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
/* Others*/
.note-check a[data-value=Rubik] {
  font-style: normal;
}
.mbr-arrow a {
  color: #ffffff;
}
@media (max-width: 767px) {
  .mbr-arrow {
    display: none;
  }
}
.form-control-label {
  position: relative;
  cursor: pointer;
  margin-bottom: .357em;
  padding: 0;
}
.alert {
  color: #ffffff;
  border-radius: 0;
  border: 0;
  font-size: .875rem;
  line-height: 1.5;
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  position: relative;
}
.alert.alert-form::after {
  background-color: inherit;
  bottom: -7px;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  margin-left: -7px;
  position: absolute;
  transform: rotate(45deg);
  width: 14px;
  -webkit-transform: rotate(45deg);
}
.form-control {
  background-color: #f5f5f5;
  box-shadow: none;
  color: #565656;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  line-height: 1.43;
  min-height: 3.5em;
  padding: 1.07em .5em;
}
.form-control > .mbr-iconfont {
  font-size: 1.6rem;
}
.form-control,
.form-control:focus {
  border: 1px solid #e8e8e8;
}
.form-active .form-control:invalid {
  border-color: red;
}
.mbr-overlay {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: .5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
}
blockquote {
  font-style: italic;
  padding: 10px 0 10px 20px;
  font-size: 1.09rem;
  position: relative;
  border-color: #149dcc;
  border-width: 3px;
}
ul,
ol,
pre,
blockquote {
  margin-bottom: 2.3125rem;
}
pre {
  background: #f4f4f4;
  padding: 10px 24px;
  white-space: pre-wrap;
}
.inactive {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  user-drag: none;
}
.mbr-section__comments .row {
  justify-content: center;
  -webkit-justify-content: center;
}
/* Forms */
.mbr-form .btn {
  margin: .4rem 0;
}
.mbr-form .input-group-btn a.btn {
  border-radius: 100px !important;
}
.mbr-form .input-group-btn a.btn:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.mbr-form .input-group-btn button[type="submit"] {
  border-radius: 100px !important;
  padding: 1rem 3rem;
}
.mbr-form .input-group-btn button[type="submit"]:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.form2 .form-control {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.form2 .input-group-btn a.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.form2 .input-group-btn button[type="submit"] {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.form3 input[type="email"] {
  border-radius: 100px !important;
}
@media (max-width: 349px) {
  .form2 input[type="email"] {
    border-radius: 100px !important;
  }
  .form2 .input-group-btn a.btn {
    border-radius: 100px !important;
  }
  .form2 .input-group-btn button[type="submit"] {
    border-radius: 100px !important;
  }
}
@media (max-width: 767px) {
  .btn {
    font-size: .75rem !important;
  }
  .btn .mbr-iconfont {
    font-size: 1rem !important;
  }
}
/* Social block */
.btn-social {
  font-size: 20px;
  border-radius: 50%;
  padding: 0;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  position: relative;
  border: 2px solid #c0a375;
  border-color: #149dcc;
  color: #232323;
  cursor: pointer;
}
.btn-social i {
  top: 0;
  line-height: 44px;
  width: 44px;
}
.btn-social:hover {
  color: #fff;
  background: #149dcc;
}
.btn-social + .btn {
  margin-left: .1rem;
}
/* Footer */
.mbr-footer-content li::before,
.mbr-footer .mbr-contacts li::before {
  background: #149dcc;
}
.mbr-footer-content li a:hover,
.mbr-footer .mbr-contacts li a:hover {
  color: #149dcc;
}
.footer3 input[type="email"],
.footer4 input[type="email"] {
  border-radius: 100px !important;
}
.footer3 .input-group-btn a.btn,
.footer4 .input-group-btn a.btn {
  border-radius: 100px !important;
}
.footer3 .input-group-btn button[type="submit"],
.footer4 .input-group-btn button[type="submit"] {
  border-radius: 100px !important;
}
/* Headers*/
.header13 .form-inline input[type="email"],
.header14 .form-inline input[type="email"] {
  border-radius: 100px;
}
.header13 .form-inline input[type="text"],
.header14 .form-inline input[type="text"] {
  border-radius: 100px;
}
.header13 .form-inline input[type="tel"],
.header14 .form-inline input[type="tel"] {
  border-radius: 100px;
}
.header13 .form-inline a.btn,
.header14 .form-inline a.btn {
  border-radius: 100px;
}
.header13 .form-inline button,
.header14 .form-inline button {
  border-radius: 100px !important;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
  .offset-sm-0 {
    margin-left: 0%;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 768px) {
  .offset-md-0 {
    margin-left: 0%;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 992px) {
  .offset-lg-0 {
    margin-left: 0%;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1200px) {
  .offset-xl-0 {
    margin-left: 0%;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}
.navbar-toggler {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  -webkit-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  -webkit-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.navbar-toggler-left {
  position: absolute;
  left: 1rem;
}
.navbar-toggler-right {
  position: absolute;
  right: 1rem;
}
@media (max-width: 575px) {
  .navbar-toggleable .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-toggleable > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-toggleable {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-toggleable .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-toggleable > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }
  .navbar-toggleable .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar-toggleable-sm .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-toggleable-sm > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-toggleable-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-toggleable-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-toggleable-sm > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-sm .navbar-collapse {
    display: none;
    width: 100%;
  }
  .navbar-toggleable-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar-toggleable-md .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-toggleable-md > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-toggleable-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-toggleable-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-toggleable-md > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-md .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }
  .navbar-toggleable-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199px) {
  .navbar-toggleable-lg .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-toggleable-lg > .container {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-toggleable-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-toggleable-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-toggleable-lg > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar-toggleable-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }
  .navbar-toggleable-lg .navbar-toggler {
    display: none;
  }
}
.navbar-toggleable-xl {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggleable-xl .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-toggleable-xl > .container {
  padding-right: 0;
  padding-left: 0;
}
.navbar-toggleable-xl .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-toggleable-xl .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}
.navbar-toggleable-xl > .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggleable-xl .navbar-collapse {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  width: 100%;
}
.navbar-toggleable-xl .navbar-toggler {
  display: none;
}
.card-img {
  width: auto;
}
.menu .navbar.collapsed:not(.beta-menu) {
  flex-direction: column;
  -webkit-flex-direction: column;
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.note-air-layout .dropup .dropdown-menu,
.note-air-layout .navbar-fixed-bottom .dropdown .dropdown-menu {
  bottom: initial !important;
}
html,
body {
  height: auto;
  min-height: 100vh;
}
.dropup .dropdown-toggle::after {
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-wrapper {
    flex: auto!important;
  }
}
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #149dcc;
  color: #ffffff;
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #149dcc;
  border-bottom-color: #149dcc;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #ffffff !important;
  background-color: #149dcc !important;
  box-shadow: none!important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #ffffff !important;
  background: #ff3366 !important;
  box-shadow: none !important;
}
.cid-qTkA127IK8 {
  background-image: url("../../../components/blockplay/data/blocks-2000x3000.jpeg");
}
.cid-qTkA127IK8 H1 {
  color: #efefef;
}
.cid-qTkA127IK8 .mbr-text,
.cid-qTkA127IK8 .mbr-section-btn {
  color: #ffffff;
}
.cid-rw667MlIi9 {
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: #ffffff;
}
.cid-rw667MlIi9 h2 {
  text-align: center;
}
.cid-rw667MlIi9 h3 {
  text-align: center;
  font-weight: 300;
}
.cid-rw667MlIi9 p {
  color: #767676;
}
.cid-rw667MlIi9 img {
  object-fit: cover;
}
.cid-rw667MlIi9 .block-content {
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  flex-basis: 100%;
  -webkit-flex-basis: 100%;
}
.cid-rw667MlIi9 .mbr-figure {
  align-self: flex-start;
  -webkit-align-self: flex-start;
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
}
.cid-rw667MlIi9 .media-container-row {
  word-wrap: break-word;
  word-break: break-word;
}
.cid-rw667MlIi9 .mbr-section-subtitle {
  color: #767676;
}
.cid-rw667MlIi9 .card-title {
  font-weight: 500;
}
.cid-rw667MlIi9 .card-img {
  text-align: inherit;
}
.cid-rw667MlIi9 .card-img span {
  font-size: 48px;
  color: #707070;
}
@media (min-width: 992px) {
  .cid-rw667MlIi9 .mbr-figure {
    padding-right: 2rem;
    padding-left: 2rem;
    height: 100%;
  }
}
@media (max-width: 991px) {
  .cid-rw667MlIi9 .mbr-figure {
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cid-rw667MlIi9 .block-content {
    flex-direction: row;
    -webkit-flex-direction: row;
    text-align: center;
  }
  .cid-rw667MlIi9 .block-content .card {
    flex-basis: 100%;
    -webkit-flex-basis: 100%;
  }
  .cid-rw667MlIi9 .card:nth-child(1) {
    padding-bottom: 0!important;
  }
}
.cid-rw64fHa2QM {
  padding-top: 30px;
  padding-bottom: 30px;
  background-image: url("../../../components/blockplay/data/blocks-1454x2181.jpg");
}
.cid-rw64fHa2QM .title {
  margin-bottom: 2rem;
}
.cid-rw64fHa2QM .mbr-section-subtitle {
  color: #767676;
}
.cid-rw64fHa2QM a:not([href]):not([tabindex]) {
  color: #fff;
  border-radius: 3px;
}
.cid-rw64fHa2QM a.btn-white:not([href]):not([tabindex]) {
  color: #333;
}
.cid-rw64fHa2QM textarea.form-control {
  min-height: 188px;
}
.cid-rw64iA7S8k {
  padding-top: 90px;
  padding-bottom: 90px;
  background: linear-gradient(0deg, #7fdbfd, #6670a9);
}
.cid-rw64iA7S8k .mbr-iconfont-social {
  font-size: 32px;
  color: #ff3366;
}
.cid-rw64iA7S8k .social-list a:focus {
  text-decoration: none;
}
.cid-rw64iA7S8k H2 {
  color: #c1c1c1;
}
